@import "../../../assets/styles/variables";

.my-learnings {
  padding-bottom: 70px;
  @media (max-width: 992px) {
    padding-bottom: 40px;
  }

  .my-learnings-listing {
    padding-top: 30px;
    padding-bottom: 40px;
  }

  .title-section {
    .section-title {
      font-size: 36px;
      color: $black;
      font-weight: $font-weight-medium;
      margin-bottom: 16px;
      @media (max-width: 768px) {
        font-size: 24px;
      }
    }
    .search-filter-wrap {
      .search-wrap {
        max-width: 384px;
        flex-grow: 1;
        .form-control-wrap {
          .h-0 {
            height: 0px;
          }
          .auto-suggestions-menu {
            position: absolute;
            left: 0;
            right: 0;
            margin-top: 5px;
            background-color: $white;
            box-shadow: $drop-shadow;
            z-index: 2;
            border-radius: $border-radius-sm;
            overflow: hidden;

            ul {
              list-style: none;
              padding-left: 0;
              margin-bottom: 0;
              li {
                .suggestion {
                  padding: 10px 16px;
                  text-align: left;
                  font-size: 16px;
                  color: $black;
                  font-weight: $font-weight-light-medium;
                  width: 100%;
                  &:hover {
                    background-color: $tertiary;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .tab-section {
    .nav-pills {
      width: fit-content;
      border-bottom: 4px solid $tertiary;

      .nav-item {
        margin-right: 40px;
        margin-bottom: 4px;
        .nav-link {
          font-size: 20px;
          font-weight: $font-weight-light-medium;
          padding: 0;
          position: relative;
          transition: all 0.4 ease;
          box-shadow: none;
          &:hover {
            font-weight: $font-weight-bold;
          }
          &.active {
            font-weight: $font-weight-bold;
            background-color: transparent;
            color: $primary;
            &::before {
              content: "";
              position: absolute;
              width: 100%;
              height: 4px;
              background-color: $primary;
              left: 0;
              bottom: -8px;
            }
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
