@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

$primary: #d43d35;
$primary-2: #821c18;
$primary-3: #c21b12;
$primary-4: #f6e8e7;
$primary-5: #b22525;
$primary-6: #db5d57;
$primary-7: #f6d8d7;
$primary-3-opacity: #c21b1225;
$primary-1-opacity: #d43d3548;
$primary-1-opacity-20: #d43d3520;
$primary-5-opacity-20: #b2252520;
$secondary: #f1bebc;
$tertiary: #f6e8e7;
$danger: #d43d35;
$black: #000000;
$black-2: #292d32;
$blue: #125ae3;
$grey: #848484;
$grey-2: #bcbcbc;
$grey-3: #ddd;
$grey-4: #6e6d6d;
$grey-5: #d9d9d9;

$green: #24cb3f;

$white: #ffffff;
$white-2: #f8f9fb;

$bg-white: #f8f9fb;

$border-clr: #4c4c4c;

$dropdown-item-hover-clr: #e7ebff;

$scroll-bar-track-clr: #ededed;
$scroll-bar-thumb-clr: #cacaca;
$scroll-bar-thumb-hover-clr: #c7d6ff;

$scroll-bar-border-radius: 10px;

$drop-shadow: 0px 4px 4px rgba(189, 189, 189, 0.25);

$border-radius-xs: 4px;
$border-radius-sm: 8px;
$border-radius-md: 12px;
$border-radius-lg: 16px;

$global-font: "Poppins", sans-serif;

$font-weight-light: 300;
$font-weight-light-medium: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;
