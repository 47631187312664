@import "../../assets/styles/variables";

.chat-component {
  position: fixed;
  bottom: 125px;
  right: 48px;
  z-index: 1;
  overflow: hidden;
  transition: all 0.3s ease;
  transform: scale(0);
  transform-origin: right bottom;
  opacity: 0;
  &.show {
    opacity: 1;
    transform: scale(1);
  }
  @media (max-width: 768px) {
    right: 20px;
    bottom: 100px;
  }
  .doubts-wrapper {
    border-radius: $border-radius-lg;
    background-color: $tertiary;
    padding: 20px;
    max-width: 420px;
    position: relative;
    box-shadow: 0px 4px 4px 0px rgba(126, 126, 126, 0.25);
    margin-left: 20px;
    margin-right: 10px;
    z-index: 2;

    .title-sec {
      border-bottom: 2px solid $secondary;
      .title {
        font-size: 20px;
        font-weight: $font-weight-medium;
        color: $black;
        margin-bottom: 16px;
      }
    }
    .chat-section {
      height: 320px;
      overflow-x: hidden;
      overflow-y: auto;
    }
    .chat-bubble-wrapper {
      display: flex;
      margin-top: 14px;
      .avatar-wrap {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        @media (max-width: 768px) {
          width: 32px;
          height: 32px;
        }
      }
      .chat-bubble {
        width: 60%;
        padding: 6px 12px;
        height: fit-content;
        .message {
          font-size: 12px;
          line-height: 16px;
          font-weight: $font-weight-light-medium;
        }
        &.user-chat-bubble {
          border-radius: $border-radius-sm 0 $border-radius-sm $border-radius-sm;
          background-color: $secondary;
          color: $black;
          margin-left: auto;
          margin-right: 10px;
        }
        &.tutor-chat-bubble {
          border-radius: 0 $border-radius-sm $border-radius-sm $border-radius-sm;
          background-color: $primary-6;
          color: $white-2;
        }
      }
    }
    .input-wrapper {
      padding-top: 16px;
      .form-group {
        position: relative;
        .form-control {
          box-shadow: none;
          border: none;
          height: 48px;
        }
        .send-btn {
          position: absolute;
          height: 32px;
          width: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: $border-radius-xs;
          background-color: $tertiary;
          right: 8px;
          top: 50%;
          transform: translateY(-50%);
          svg {
            width: 22px;
            height: 22px;
          }
        }
      }
    }
  }
}
.floating-btn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 60px;
  right: 48px;
  z-index: 1;
  svg {
    width: 32px;
    height: 32px;
  }
  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    right: 20px;
    bottom: 60px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
