@import "../../../assets/styles/variables";

.cart-item-card {
  padding: 16px;
  border-radius: $border-radius-lg;
  background-color: $tertiary;
  display: flex;
  gap: 16px;
  @media (max-width: 992px) {
    padding: 12px;
    gap: 12px;
  }
  @media (max-width: 768px) {
    padding: 8px;
    gap: 8px;
  }
  &:hover {
    .thumbnail {
      transform: scale(1.3);
    }
  }
  .thumbnail-wrap {
    width: 132px;
    height: 132px;
    overflow: hidden;
    border-radius: $border-radius-sm;
    .thumbnail {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s ease;
    }
    @media (max-width: 992px) {
      width: 100px;
      height: 100px;
    }
    @media (max-width: 768px) {
      width: 85px;
      height: 85px;
    }
  }
  .course-card-body {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 16px;
    .details {
      max-width: 380px;
      @media (max-width: 1200px) {
        max-width: 320px;
      }
      @media (max-width: 992px) {
        max-width: 80%;
      }
      @media (max-width: 768px) {
        max-width: 75%;
      }
    }
    .title-wrap {
      .course-card-title {
        font-size: 20px;
        font-weight: $font-weight-medium;
        display: -webkit-box;
        line-clamp: 1;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 8px;
        @media (max-width: 992px) {
          font-size: 16px;
          margin-bottom: 4px;
        }
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
      .course-card-desc {
        font-size: 16px;
        font-weight: $font-weight-light-medium;
        color: $grey;
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 8px;
        @media (max-width: 992px) {
          font-size: 14px;
          margin-bottom: 4px;
        }
        @media (max-width: 768px) {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
    .btn-sec {
      gap: 16px;
      flex-wrap: wrap;
      .btn-sm {
        padding: 0;
        @media (max-width: 576px) {
          line-height: 1;
        }
      }
      @media (max-width: 768px) {
        row-gap: 5px;
        column-gap: 10px;
        .btn-sm {
          line-height: 1;
          font-size: 12px;
        }
      }
    }

    .price-wrap {
      .new-price {
        color: $primary;
        font-size: 24px;
        font-weight: $font-weight-medium;
        white-space: nowrap;
        @media (max-width: 992px) {
          font-size: 16px;
        }
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
      .old-price {
        font-size: 16px;
        font-weight: $font-weight-light-medium;
        color: $grey-2;
        margin-left: 4px;
        line-height: 1;
        white-space: nowrap;
        @media (max-width: 992px) {
          font-size: 12px;
        }
        &.striked {
          text-decoration: line-through;
        }
      }
    }
  }
}
