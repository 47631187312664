@import "../../../assets/styles/variables";

.course-card {
  padding: 16px;
  border-radius: $border-radius-lg;
  background-color: $tertiary;

  &:hover {
    .thumbnail {
      transform: scale(1.3);
    }
  }
  .thumbnail-wrap {
    max-height: 274px;
    width: 100%;
    overflow: hidden;
    border-radius: $border-radius-sm;
    margin-bottom: 8px;
    .thumbnail {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s ease;
    }
  }
  .course-card-body {
    .title-wrap {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      .course-card-title {
        font-size: 20px;
        font-weight: $font-weight-medium;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 3px;
        @media (max-width: 576px) {
          font-size: 16px;
        }
      }
      .wishlist-btn {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $border-radius-xs;
        background-color: $secondary;
        @media (max-width: 576px) {
          width: 26px;
          height: 26px;
          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
    .course-card-desc {
      font-size: 14px;
      font-weight: $font-weight-light-medium;
      color: $grey;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 8px;
    }
    .price-wrap {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      .new-price {
        color: $primary;
        font-size: 20px;
        font-weight: $font-weight-medium;
        white-space: nowrap;
      }
      .old-price {
        font-size: 14px;
        font-weight: $font-weight-light-medium;
        color: $grey-2;
        margin-left: 4px;
        line-height: 1;
        white-space: nowrap;
        &.striked {
          text-decoration: line-through;
        }
      }
    }
    .progress-wrap {
      .progress-title {
        font-size: 16px;
        font-weight: $font-weight-medium;
        color: $black;
        margin-bottom: 8px;
      }
      .progress-track {
        width: 100%;
        height: 8px;
        background-color: $secondary;
        border-radius: $border-radius-md;
        margin-bottom: 4px;
        .progress-bar {
          background-color: $primary;
          height: 100%;
          // width: 60%;
          border-radius: $border-radius-md;
        }
      }
      .text {
        font-size: 14px;
        font-weight: $font-weight-light-medium;
        color: $black;
        line-height: 1;
      }
    }
  }
}
