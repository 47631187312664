@import "../../assets/styles/variables";

.navbar {
  .navbar-brand {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: $font-weight-bold;
    color: $white-2;

    &:hover {
      color: $white-2;
    }
    .navbar-logo {
      max-width: 50px;
      @media (max-width: 992px) {
        max-width: 42px;
      }
    }
  }

  .navbar-toggler {
    padding: 0;
    border: none;
    .hamburger-menu-icon {
      position: relative;
      width: 22px;
      position: relative;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.5s ease-in-out;
      -moz-transition: 0.5s ease-in-out;
      -o-transition: 0.5s ease-in-out;
      transition: 0.5s ease-in-out;
      cursor: pointer;
      .line {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: $white;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
      }

      .line-1 {
        top: -8px;
      }

      .line-2 {
        top: -1px;
      }

      .line-3 {
        top: 6px;
      }

      &.open .line-1 {
        top: -2px;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
      }

      &.open .line-2 {
        opacity: 0;
        left: -60px;
      }

      &.open .line-3 {
        top: -2px;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
    }
    &:focus {
      box-shadow: none;
    }
  }

  .notification-dropdown {
    margin-left: 16px;
    margin-right: 32px;
    .notification-btn {
      position: relative;
      svg {
        width: 24px;
        height: 24px;
      }
      .notification-dot {
        position: absolute;
        width: 16px;
        height: 16px;
        background-color: $blue;
        color: $white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        right: -4px;
        top: -5px;
      }

      &::after {
        display: none;
      }
    }
    .dropdown-menu {
      .title-wrap {
        padding-left: 10px;
        padding-right: 10px;
        .underline {
          border-bottom: 1px solid $grey-3;
        }
        .dropdown-title {
          color: $black;
          font-weight: $font-weight-medium;
          font-size: 14px;
          padding-bottom: 8px;
        }
      }

      .dropdown-item {
        padding-top: 0;
        padding-bottom: 0;
        p {
          font-size: 10px;
          font-weight: $font-weight-medium;
          line-height: 16px;
          margin-top: 4px;
        }
        .line {
          max-width: 240px;
          display: flex;
          padding: 4px 0;
          border-bottom: 1px solid $grey-3;
          width: 100%;
        }
      }
    }
  }

  .dropdown-menu {
    background-color: $bg-white;
    filter: drop-shadow(0px 4px 4px rgba(143, 143, 143, 0.25));
    border: none;
    .dropdown-item {
      display: flex;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 14px;
      font-weight: $font-weight-medium;
      color: $black;
      &:hover {
        background-color: $primary-4;
      }
      .circle {
        width: 24px;
        height: 24px;
        padding: 1px 6px;
        border-radius: 50%;
        background-color: $primary-5-opacity-20;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  .avatar-dropdown {
    .avatar-btn {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      overflow: hidden;
      border: 2px solid $grey-3;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      @media (max-width: 768px) {
        width: 32px;
        height: 32px;
      }
    }
  }

  &.navbar-expand-lg {
    padding-top: 0;
    padding-bottom: 0;
    .navbar-nav {
      gap: 8px;
      .nav-item {
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        .nav-link {
          font-size: 16px;
          color: $white-2;
          font-weight: $font-weight-light-medium;
          padding-left: 8px;
          padding-right: 8px;
          position: relative;
          width: fit-content;
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            transform: scaleX(0);
            height: 3px;
            background-color: $white-2;
            transition: all 0.3s ease-in-out;
            transform-origin: center;
            @media (max-width: 768px) {
              display: none;
            }
          }
          &:hover {
            &::after {
              transform: scaleX(1);
            }
          }
          &.active {
            font-weight: $font-weight-bold;
            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              transform: scaleX(1);
              height: 3px;
              background-color: $white-2;
            }
          }

          .arrow {
            width: 20px;
            height: 20px;
            margin-left: 3px;
            transition: all 0.3s ease;
          }

          @media (max-width: 768px) {
            padding-left: 0;
            padding-right: 0;
            &::after {
              transform-origin: left;
            }
          }
        }
        .sub-menu {
          position: absolute;
          top: 64px;
          left: 0;
          line-height: 40px;
          background: $primary;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
          border-radius: 0 0 4px 4px;
          display: none;
          z-index: 2;
          min-width: 160px;
          list-style: none;
          padding-left: 0;
          .nav-item {
            border-bottom: 1px solid $primary-4;
            position: relative;
            .nav-link {
              color: $white;
              font-size: 16px;
              width: 100%;
              line-height: 1;
              position: relative;
              &::after {
                display: none;
              }
            }
            &:hover {
              .rotating-arrow-inner {
                transform: rotate(180deg);
              }
              .more-sub-menu {
                display: block;
              }
            }
            &:last-child {
              border-bottom: none;
            }
          }
          .more-sub-menu {
            position: absolute;
            left: 100%;
            top: 0;
            display: none;
          }
        }
        &:hover {
          .rotating-arrow {
            transform: rotate(180deg);
          }
          .sub-menu-1 {
            display: block;
          }
        }
      }
      @media (max-width: 768px) {
        padding-top: 16px;
      }
    }
  }

  @media (min-width: 768px) {
    &.navbar-expand-lg {
      .navbar-toggler {
        display: none;
      }
      .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
      }
      .navbar-nav {
        flex-direction: row;
        gap: 36px;
      }
    }
  }
  @media (max-width: 768px) {
    .mobile-menu {
      .navbar-nav {
        .nav-item {
          height: fit-content;
          padding-left: 16px;
          padding-right: 16px;
          display: block;

          .sub-menu {
            position: relative;
            background-color: transparent;
            top: unset;
            box-shadow: none;
            min-width: unset;
            margin-left: 10px;
            .nav-item {
              border-bottom: none;
              font-size: 14px;
              display: block;
              width: fit-content;
            }
            .more-sub-menu {
              position: relative;
              left: unset;
            }
          }
        }
      }
    }
  }
}
