@import "../../../../assets/styles/variables";

.registration-steps-wrapper {
  height: 100vh;
  overflow: hidden;
  background-color: $bg-white;
  position: relative;

  @media (max-width: 992px) {
    height: auto;
  }
  .logo-wrapper {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    .logo {
      max-width: 120px;
      @media (max-width: 992px) {
        max-width: 80px;
      }
      @media (max-width: 768px) {
        max-width: 60px;
      }
    }
  }
  .sectors-top {
    position: absolute;
    top: 0;
    left: 0;
    .sector-wrap {
      position: relative;
      .sector-1 {
        position: absolute;
        top: -320px;
        left: -320px;
        width: 584px;
        height: 584px;
        border-radius: 50%;
        background-color: rgba(212, 61, 53, 0.45);
        display: flex;
        align-items: center;
        justify-content: center;
        .sector-2 {
          width: 496px;
          height: 496px;
          border-radius: 50%;
          background-color: rgba(212, 61, 53, 0.6);
          display: flex;
          align-items: center;
          justify-content: center;
          .sector-3 {
            width: 430px;
            height: 430px;
            border-radius: 50%;
            background-color: $primary;
          }
        }
        @media (max-width: 992px) {
          top: -360px;
          left: -360px;
        }
        @media (max-width: 768px) {
          width: 200px;
          height: 200px;
          top: -80px;
          left: -90px;
          .sector-2 {
            width: 180px;
            height: 180px;
            .sector-3 {
              width: 160px;
              height: 160px;
            }
          }
        }
      }
    }
  }
  .sectors-bottom {
    position: absolute;
    bottom: 0;
    right: 0;
    .sector-wrap {
      position: relative;
      .sector-1 {
        position: absolute;
        bottom: -310px;
        right: -290px;
        width: 584px;
        height: 584px;
        border-radius: 50%;
        background-color: rgba(212, 61, 53, 0.45);
        display: flex;
        align-items: center;
        justify-content: center;
        .sector-2 {
          width: 496px;
          height: 496px;
          border-radius: 50%;
          background-color: rgba(212, 61, 53, 0.6);
          display: flex;
          align-items: center;
          justify-content: center;
          .sector-3 {
            width: 430px;
            height: 430px;
            border-radius: 50%;
            background-color: $primary;
          }
        }
        @media (max-width: 992px) {
          bottom: -390px;
          right: -360px;
        }
        @media (max-width: 768px) {
          width: 200px;
          height: 200px;
          bottom: -90px;
          right: -90px;
          .sector-2 {
            width: 180px;
            height: 180px;
            .sector-3 {
              width: 160px;
              height: 160px;
            }
          }
        }
      }
    }
  }
  .stepper-wrap-container {
    background-color: $bg-white;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 20px;

    @media (max-width: 768px) {
      padding-top: 26px;
      //   padding-bottom: 26px;
    }
    .content-section {
      text-align: center;
      padding: 0px;
      padding-top: 80px;
      padding-bottom: 100px;
      @media (max-width: 992px) {
        padding-top: 60px;
      }
      @media (max-width: 768px) {
        padding-top: 0px;
      }
      .title-wrapper {
        max-width: 400px;
        margin-inline: auto;
        .logo-title {
          font-size: 24px;
          font-weight: $font-weight-bold;
          color: $black-2;
          line-height: 36px;
          margin-bottom: 50px;
          @media (max-width: 768px) {
            margin-bottom: 46px;
          }
        }
        .form-title {
          font-size: 36px;
          color: $black;
          font-weight: $font-weight-medium;
          @media (max-width: 768px) {
            font-size: 24px;
          }
        }
        .description {
          font-size: 16px;
          color: $grey;
          margin-bottom: 48px;
          @media (max-width: 992px) {
            margin-bottom: 30px;
          }
          @media (max-width: 768px) {
            font-size: 14px;
            margin-bottom: 20px;
          }
        }
      }

      .step-indicator {
        max-width: 400px;
        margin-inline: auto;
        .stepper-container {
          padding: 0;
          padding-bottom: 50px;
          .step-wrapper {
            border: 2px solid $primary;
            & + div {
              //   max-width: 100px;
              font-size: 12px;
            }
          }
          @media (max-width: 992px) {
            padding-bottom: 42px;
          }
          @media (max-width: 768px) {
            padding-bottom: 30px;
          }
        }
      }
      .mw-480 {
        max-width: 480px;
      }
      .country-code-selector {
        .form-control {
          width: 100%;
          box-shadow: none;
          height: 52px;
          @media (max-width: 768px) {
            height: 40px;
          }
        }
        .react-tel-input .selected-flag:focus:before,
        .react-tel-input .selected-flag.open:before {
          border: none;
          box-shadow: none;
        }
        .react-tel-input .country-list {
          width: 480px;
          @media (max-width: 576px) {
            width: 300px;
          }
        }
      }
      .otp-field-wrap {
        // max-width: 300px;
        margin-inline: auto;
        .timer {
          color: $primary;
          font-size: 14px;
          font-weight: $font-weight-light-medium;
        }
        .desc {
          font-size: 14px;
        }
        .font-14 {
          font-size: 14px;
        }
        @media (max-width: 768px) {
          max-width: 100%;
        }
      }
      .otp-fields {
        display: flex;
        width: 100%;
        .otp-field {
          text-align: center;
          width: 52px;
          height: 52px;
          @media (max-width: 768px) {
            width: 40px;
            height: 40px;
          }
        }
      }
      .category-section {
        max-width: 900px;
        margin-inline: auto;
        @media (max-width: 992px) {
          max-width: 420px;
        }
      }
    }
    .step-btn {
      min-width: 120px;
      font-weight: $font-weight-bold;
      @media (max-width: 768px) {
        min-width: 80px;
        padding: 8px 12px;
        font-size: 14px;
      }
    }
  }
}
