@import "../../../assets/styles/variables";

.notification-card {
  padding: 16px;
  border-radius: $border-radius-sm;
  background-color: $tertiary;
  display: flex;
  gap: 16px;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    padding: 10px;
    gap: 8px;
  }

  .icon-wrap {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: rgba(178, 37, 37, 0.2);
    @media (max-width: 768px) {
      width: 28px;
      height: 28px;
      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
  .notification-card-body {
    .notification-card-title {
      font-size: 20px;
      font-weight: $font-weight-light-medium;
      display: -webkit-box;
      line-height: 30px;
      margin-bottom: 8px;
      // line-clamp: 1;
      // -webkit-line-clamp: 1;
      // -webkit-box-orient: vertical;
      // overflow: hidden;
      // text-overflow: ellipsis;
      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 4px;
      }
    }

    .notification-card-desc {
      font-size: 16px;
      font-weight: $font-weight-light-medium;
      color: $grey;
      line-height: 24px;
      // display: -webkit-box;
      // line-clamp: 2;
      // -webkit-line-clamp: 2;
      // -webkit-box-orient: vertical;
      // overflow: hidden;
      // text-overflow: ellipsis;
      @media (max-width: 768px) {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
