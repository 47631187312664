@import "../../../assets/styles/variables";

.course-streaming-page {
  .course-streaming {
    padding-top: 40px;
    @media (max-width: 768px) {
      padding-top: 16px;
    }
    .title-wrapper {
      display: flex;
      margin-bottom: 36px;
      .back-btn {
        width: 48px;
        height: 48px;
        border-radius: $border-radius-sm;
        background-color: $tertiary;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        svg {
          path {
            stroke: $black-2;
          }
        }
        @media (max-width: 992px) {
          width: 36px;
          height: 36px;
        }
      }
      .course-title {
        font-size: 36px;
        color: $black-2;
        font-weight: $font-weight-bold;
        margin-bottom: 4px;
        // text-align: justify;

        @media (max-width: 768px) {
          font-size: 24px;
        }
      }
      .course-desc {
        font-size: 16px;
        color: $grey;
        font-weight: $font-weight-light-medium;
        line-height: 24px;
        text-align: justify;
        @media (max-width: 768px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
      @media (max-width: 768px) {
        margin-bottom: 24px;
      }
    }

    .video-player-wrapper {
      margin-bottom: 36px;
      position: relative;
      .course-title {
        z-index: 1;
        position: absolute;
        top: 26px;
        left: 26px;
        color: $white;
        font-size: 20px;
        font-weight: $font-weight-medium;
        @media (max-width: 768px) {
          top: 14px;
          left: 14px;
          font-size: 14px;
        }
      }
      @media (max-width: 768px) {
        margin-bottom: 24px;
      }
      .video-player,
      .test-area {
        width: 100%;
        min-height: 516px;
        background-color: $tertiary;

        border-radius: $border-radius-lg;
        @media (max-width: 992px) {
          min-height: 420px;
        }
        @media (max-width: 768px) {
          min-height: 360px;
        }
      }
      .video-player {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .test-area {
        padding: 40px;
        .test-wrapper {
          display: flex;
          flex-direction: column;
          min-height: 90%;
          @media (max-width: 768px) {
            // height: auto;
          }
        }
        .test-title {
          font-size: 24px;
          font-weight: $font-weight-medium;
          color: $black;
          margin-bottom: 20px;
          @media (max-width: 768px) {
            font-size: 20px;
          }
        }
        .question {
          font-size: 24px;
          font-weight: $font-weight-medium;
          color: $black;
          @media (max-width: 768px) {
            font-size: 20px;
          }
        }
        .results-sec {
          .result-title {
            font-size: 24px;
            font-weight: $font-weight-medium;
            color: $black;
            margin-bottom: 14px;
            @media (max-width: 768px) {
              font-size: 20px;
              margin-bottom: 0;
            }
          }
          .results {
            font-size: 16px;
            font-weight: $font-weight-light-medium;
            color: $black;
            .score {
              font-weight: $font-weight-bold;
            }
            .green {
              color: $green;
            }
            .red {
              color: $primary;
            }
          }
        }
      }
    }

    .nav-wrap {
      width: fit-content;
      @media (max-width: 576px) {
        width: 100%;
        overflow-x: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }

      .nav-pills {
        flex-wrap: nowrap;
        border-bottom: 4px solid $tertiary;
        width: fit-content;
        .nav-item {
          margin-right: 40px;
          margin-bottom: 4px;
          .nav-link {
            font-size: 20px;
            font-weight: $font-weight-light-medium;
            padding: 0;
            position: relative;
            transition: all 0.4 ease;
            box-shadow: none;
            &:hover {
              font-weight: $font-weight-bold;
            }
            &.active {
              font-weight: $font-weight-bold;
              background-color: transparent;
              color: $primary;
              &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 4px;
                background-color: $primary;
                left: 0;
                bottom: -8px;
              }
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .tab-content {
      // min-height: 300px;
      margin-bottom: 40px;
      @media (max-width: 992px) {
        margin-bottom: 32px;
      }
      @media (max-width: 768px) {
        min-height: auto;
      }
      .mw-962 {
        max-width: 962px;
      }
      .tab-title-wrap {
        margin-bottom: 36px;
        @media (max-width: 768px) {
          margin-bottom: 24px;
        }
        .tab-title {
          font-size: 20px;
          color: $black;
          font-weight: $font-weight-medium;
        }
        .desc {
          font-size: 16px;
          color: $grey-4;
          font-weight: $font-weight-light-medium;
          margin-top: 8px;
          text-align: justify;
        }
      }
      .test-score-card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 20px;
        border-radius: $border-radius-sm;
        background-color: $primary-4;
        margin-bottom: 16px;
        gap: 16px;
        min-height: 56px;
        flex-wrap: wrap;
        .title,
        .score {
          font-size: 16px;
          font-weight: $font-weight-medium;
          color: $black;
          @media (max-width: 768px) {
            font-size: 12px;
          }
        }
        .score {
          font-weight: $font-weight-bold;
        }
        .green {
          color: $green;
        }
        .red {
          color: $primary;
        }
        .btn-sm {
          height: 32px;
        }
        @media (max-width: 768px) {
          padding: 10px 14px;
          .btn-sm {
            height: 28px;
          }
        }
      }
    }
  }

  .course-content-section {
    padding: 16px 12px;
    border-radius: $border-radius-lg;
    background-color: $primary-4;
    .course-sec-scroll-area {
      @media (min-width: 1200px) {
        max-height: 475px;
        overflow-y: auto;
        padding-right: 10px;
      }
    }
    .chapter-accordion {
      background-color: $primary-7;
      border-radius: $border-radius-sm;
      margin-bottom: 12px;
      @media (max-width: 1200px) {
        margin-bottom: 16px;
      }
      @media (max-width: 768px) {
        margin-bottom: 8px;
      }
      .accordion-header {
        .accordion-button {
          background-color: $primary-7;
          border: none;
          box-shadow: none;
          border-radius: $border-radius-sm;
          font-size: 16px;
          font-weight: $font-weight-medium;
          color: $black;
          padding: 8px 12px;
          @media (max-width: 1200px) {
            font-size: 20px;
            padding: 12px 20px;
          }
          @media (max-width: 768px) {
            font-size: 16px;
            padding: 8px 16px;
          }
        }
      }
      .accordion-body {
        padding: 8px 12px;
        padding-top: 0;
        @media (max-width: 1200px) {
          padding: 12px 20px;
          padding-top: 0;
        }
        @media (max-width: 768px) {
          padding: 8px 16px;
          padding-top: 0;
        }

        .inner-card {
          background-color: $bg-white;
          border: none;
          box-shadow: none;
          border-radius: $border-radius-xs;
          padding: 12px 10px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;
          @media (max-width: 768px) {
            padding: 8px 10px;
          }
          &.completed {
            background-color: $secondary;
          }
          .start-icon {
            line-height: 1;
            svg {
              width: 20px;
              height: 20px;
            }
          }
          .end-icon {
            line-height: 1;
            svg {
              width: 20px;
              height: 20px;
            }
          }
          .circle {
            width: 17px;
            height: 17px;
            border-radius: 50%;
            border: 3px solid $secondary;
          }

          .inner-heading {
            font-size: 14px;
            font-weight: $font-weight-medium;
            color: $black;
            @media (max-width: 1200px) {
              font-size: 16px;
            }
            @media (max-width: 768px) {
              font-size: 12px;
            }
          }
          .text {
            font-size: 14px;
            font-weight: $font-weight-light-medium;
            color: $black;
            @media (max-width: 1200px) {
              font-size: 16px;
            }
            @media (max-width: 768px) {
              font-size: 12px;
            }
          }
          @media (max-width: 1200px) {
            margin-bottom: 16px;
          }
          @media (max-width: 768px) {
            margin-bottom: 8px;
          }
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .doubts-wrapper {
    border-radius: $border-radius-lg;
    background-color: $tertiary;
    padding: 20px;
    .title-sec {
      border-bottom: 2px solid $secondary;
      .title {
        font-size: 20px;
        font-weight: $font-weight-medium;
        color: $black;
        margin-bottom: 16px;
      }
    }
    .chat-section {
      height: 320px;
      overflow-x: hidden;
      overflow-y: auto;
    }
    .chat-bubble-wrapper {
      display: flex;
      margin-top: 14px;
      .avatar-wrap {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .chat-bubble {
        width: 60%;
        padding: 6px 12px;
        height: fit-content;
        .message {
          font-size: 12px;
          line-height: 16px;
          font-weight: $font-weight-light-medium;
        }
        &.user-chat-bubble {
          border-radius: $border-radius-sm 0 $border-radius-sm $border-radius-sm;
          background-color: $secondary;
          color: $black;
          margin-left: auto;
          margin-right: 10px;
        }
        &.tutor-chat-bubble {
          border-radius: 0 $border-radius-sm $border-radius-sm $border-radius-sm;
          background-color: $primary-6;
          color: $white-2;
        }
      }
    }
    .input-wrapper {
      padding-top: 16px;
      .form-group {
        position: relative;
        .form-control {
          box-shadow: none;
          border: none;
          height: 48px;
        }
        .send-btn {
          position: absolute;
          height: 32px;
          width: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: $border-radius-xs;
          background-color: $tertiary;
          right: 8px;
          top: 50%;
          transform: translateY(-50%);
          svg {
            width: 22px;
            height: 22px;
          }
        }
      }
    }
  }
}
