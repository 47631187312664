@import "../../../assets/styles/variables";

.course-details-page {
  .course-details {
    .title-wrapper {
      display: flex;
      margin-bottom: 36px;
      .back-btn {
        width: 48px;
        height: 48px;
        border-radius: $border-radius-sm;
        background-color: $tertiary;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        svg {
          path {
            stroke: $black-2;
          }
        }
        @media (max-width: 992px) {
          width: 36px;
          height: 36px;
        }
      }
      .course-title {
        font-size: 36px;
        color: $black-2;
        font-weight: $font-weight-bold;
        margin-bottom: 4px;
        // text-align: justify;

        @media (max-width: 768px) {
          font-size: 24px;
        }
      }
      .course-desc {
        font-size: 16px;
        color: $grey;
        font-weight: $font-weight-light-medium;
        line-height: 24px;
        text-align: justify;
        @media (max-width: 768px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
      @media (max-width: 768px) {
        margin-bottom: 24px;
      }
    }

    .video-player-wrapper {
      margin-bottom: 36px;
      @media (max-width: 768px) {
        margin-bottom: 24px;
      }
      .video-player {
        width: 100%;
        height: 372px;
        background-color: $tertiary;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $border-radius-lg;
        @media (max-width: 768px) {
          max-height: 160px;
        }
      }
    }

    .course-side-box {
      border-radius: $border-radius-lg;
      background-color: $tertiary;
      padding: 24px;
      margin-bottom: 36px;
      .title {
        font-size: 24px;
        color: $black;
        font-weight: $font-weight-medium;
        margin-bottom: 16px;
        @media (max-width: 768px) {
          font-size: 20px;
        }
      }

      .icon-box {
        display: flex;
        margin-bottom: 16px;
        .desc {
          font-size: 16px;
          color: $black;
          font-weight: $font-weight-light-medium;
        }
      }

      .line {
        width: 100%;
        height: 1px;
        background-color: $grey-3;
      }

      .price-section {
        padding: 16px 0;
        display: flex;
        align-items: center;
        .new-price {
          font-size: 24px;
          font-weight: $font-weight-bold;
          color: $black;
        }
        .old-price {
          font-size: 16px;
          color: $grey-2;
          font-weight: $font-weight-light-medium;
          margin-left: 8px;
          &.striked {
            text-decoration: line-through;
          }
        }
        .offer {
          font-size: 16px;
          color: $primary;
          font-weight: $font-weight-light-medium;
          margin-left: 8px;
        }
      }

      .btn-section {
        display: flex;
        align-items: center;
        gap: 16px;
        .btn-wrap {
          flex-grow: 1;
          .btn {
            width: 100%;
          }
        }
        .wish-list-btn {
          height: 50px;
          width: 48px;
          background-color: $tertiary;
          border-radius: $border-radius-sm;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      @media (max-width: 1200px) {
        border-radius: 0;
        padding: 0;
        background-color: unset;
        max-width: 580px;
      }
      @media (max-width: 768px) {
        margin-bottom: 24px;
        max-width: 100%;
      }
    }

    .sub-section {
      margin-bottom: 24px;
      .sub-section-title {
        font-size: 24px;
        color: $black;
        font-weight: $font-weight-medium;
        margin-bottom: 24px;
        @media (max-width: 768px) {
          margin-bottom: 20px;
          font-size: 20px;
        }
      }
      .summary {
        font-size: 16px;
        font-weight: $font-weight-light-medium;
        color: $grey-4;
        text-align: justify;
      }
      .course-goals {
        border-radius: $border-radius-sm;
        padding: 16px;
        background-color: $tertiary;
        .icon-box {
          display: flex;
          // margin-bottom: 16px;
          .title {
            font-size: 16px;
            font-weight: $font-weight-light-medium;
            color: $black;
            margin-top: 1px;
          }
        }
      }
    }

    .course-content-section {
      .chapter-accordion {
        background-color: $tertiary;
        border-radius: $border-radius-sm;
        margin-bottom: 16px;
        .accordion-header {
          .accordion-button {
            background-color: $tertiary;
            border: none;
            box-shadow: none;
            border-radius: $border-radius-sm;
            font-size: 20px;
            font-weight: $font-weight-medium;
            color: $black;
            @media (max-width: 768px) {
              font-size: 16px;
            }
          }
        }
        .accordion-body {
          padding-top: 0;
          .accordion-inner {
            background-color: $bg-white;
            margin-bottom: 8px;
            border-radius: $border-radius-xs;

            .accordion-header {
              .accordion-button {
                background-color: $bg-white;
                border: none;
                box-shadow: none;
                border-radius: $border-radius-xs;
                padding: 12px 16px;
                .inner-accordion-heading {
                  font-size: 16px;
                  font-weight: $font-weight-medium;
                  color: $black;
                  @media (max-width: 768px) {
                    font-size: 14px;
                  }
                }
                .text {
                  font-size: 16px;
                  font-weight: $font-weight-light-medium;
                  color: $black;
                  @media (max-width: 768px) {
                    font-size: 14px;
                  }
                }
              }
            }
            .accordion-body {
              padding-top: 0;
              padding-left: 48px;
              .desc {
                font-size: 14px;
                font-weight: $font-weight-light-medium;
                color: $grey-4;
                line-height: 18px;
                @media (max-width: 768px) {
                  font-size: 12px;
                }
              }
              ol {
                padding-left: 18px;
                list-style: none;
                li {
                  position: relative;
                  counter-increment: num;
                  &::before {
                    content: counter(num) ".";
                    color: $grey-4;
                    position: absolute;
                    top: -4px;
                    left: -18px;
                  }
                }
              }
            }
          }
          .test-card {
            background-color: $bg-white;
            border: none;
            box-shadow: none;
            border-radius: $border-radius-xs;
            padding: 12px 16px;
            .inner-accordion-heading {
              font-size: 16px;
              font-weight: $font-weight-medium;
              color: $black;
              @media (max-width: 768px) {
                font-size: 14px;
              }
            }
            .text {
              font-size: 16px;
              font-weight: $font-weight-light-medium;
              color: $black;
              @media (max-width: 768px) {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
